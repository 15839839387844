<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" centered :title="modalTitle">
        <b-form @submit.prevent="confirm">
            <b-form-group label="Nom">
                <b-form-input v-model="form.nom" type="text"/>
            </b-form-group>

            <roles-popover-wiki/>
            <b-form-group>
                <label slot="label" id="popover-roles">
                    Visibilité
                    <font-awesome-icon icon="question-circle"/>
                </label>
                <multi-select v-model="form.visible"
                              :options="rolesAvailable"
                              :multiple="false"
                              :close-on-select="true"
                              track-by="id"
                              :clear-on-select="false"
                              :preserve-search="false"
                              label="nom"/>
            </b-form-group>

            <confirm-cancel-buttons @confirm="confirm" @cancel="hideModal"/>
        </b-form>
    </b-modal>
</template>

<script>
    import modalMixin            from '@/mixin/modalMixin';
    import auth                  from '@/util/auth';
    import {checkNonEmptyFields} from '@/util/form';
    import {apiPath}             from '@/util/http';
    import alert                 from '@/util/alert';
    import wiki                  from '@/util/wiki';

    const RolesPopoverWiki     = () => import('@/components/wiki/RolesPopoverWiki');
    const ConfirmCancelButtons = () => import('@/components/ConfirmCancelButtons');
    const MultiSelect          = () => import('@/components/MultiSelect');

    export default {
        name: "EditFolderModal",
        components: {RolesPopoverWiki, ConfirmCancelButtons, MultiSelect},
        mixins: [modalMixin],
        props: {
            parent: {
                type: Object,
                default: null
            },
            dossier: {
                type: Object,
                default: null
            },
            callback: {
                type: Function,
                default: () => 0
            }
        },
        data: () => ({
            modalRef: 'EditFolderModal',
            form: {
                parent: null,
                nom: null,
                visible: null
            },
            rolesAvailable: wiki.ROLES_AVAILABLE
        }),
        computed: {
            modalTitle() {
                return this.dossier ? this.dossier.nom : 'Nouveau dossier';
            }
        },
        methods: {
            isBureau: auth.isBureau,
            confirm() {
                if (!checkNonEmptyFields(this.form, ['nom', 'visible'])) {
                    this.$toaster.error('Veuillez remplir tous les champs');
                    return;
                }

                alert.loading();
                this.axios
                    .post(apiPath('wiki_dossier_edit', this.dossier ? {dossier: this.dossier.id} : {}), {
                        ...this.form,
                        visible: this.form.visible.id // Extract only the ID to send to Symfony
                    })
                    .then(() => this.callback())
                    .catch(() => this.$toaster.error('Impossible de créer le dossier'))
                    .finally(alert.stopLoading);

                this.hideModal();
            }
        },
        mounted() {
            if (this.dossier)
                this.form = {
                    ...this.dossier,
                    parent: this.dossier.parent ? this.dossier.parent.id : null,
                    visible: wiki.getRoleSelectOption(this.dossier.visible)
                };
            else if (this.parent) {
                this.form.parent  = this.parent.id;
                // By default, apply the same visibility as the parent folder
                this.form.visible = wiki.getRoleSelectOption(this.parent.visible)
            }
        }
    }
</script>
