import store from '@/store';
import auth  from './auth';

import slug   from 'slug';
import moment from 'moment';

export const ROLES_AVAILABLE_READ_ONLY = [
    {
        id: null,
        nom: 'Public',
        icon: ['far', 'eye']
    },
    {
        id: 'ROLE_USER',
        nom: 'Utilisateur lambda',
        icon: 'child'
    },
    {
        id: 'ROLE_ANY_ASSO',
        nom: 'Association valide',
        icon: 'globe-europe'
    }
];

export const ROLES_AVAILABLE_EDIT = [
    {
        id: 'ROLE_CLUJI',
        nom: 'Membre du Cluji',
        icon: 'dragon'
    },
    {
        id: 'ROLE_BUREAU',
        nom: 'Bureau du Cluji',
        icon: 'crown'
    }
];

export const ROLES_AVAILABLE = [...ROLES_AVAILABLE_READ_ONLY, ...ROLES_AVAILABLE_EDIT];

export const momentDatesArticle = data => {
    if (Array.isArray(data)) return data.map(momentDatesArticle);

    let dto = {
        ...data,
        dateCreation: moment(data.dateCreation.date)
    };

    if (data.modifications && data.modifications.length)
        dto.modifications = data.modifications.map(m => ({...m, date: moment(m.date.date)}));

    return dto;
};

export const momentDatesBrouillon = data => Array.isArray(data) ? data.map(momentDatesBrouillon) : {
    ...data,
    date: moment(data.date.date)
};

export const isFolderEmpty = dossier => !dossier.sousDossiers.length && !dossier.articles.length;

export const getRoleSelectOption = wikiPartRole => ROLES_AVAILABLE.find(({id}) => id === wikiPartRole);

// Same permissions as in ArticleVoter
export const canEditArticle = article => auth.isBureau() ||
    auth.isUser() &&
    article.createur.id === store.state.user.utilisateur.id;

export const canDeleteArticle = article => auth.isBureau() || auth.isUser() && article.pure;

export const linkToWikiHome = route => ({
    // If we are on a dashboard, stay in the dashboard, if we are in the public page, stay there too
    name: route.matched[0].name === 'classic_pages' ? 'wiki' : 'dashboard_wiki'
});

export const linkToArticlePage = (article, route) => ({
    // If we are on a dashboard, stay in the dashboard, if we are in the public page, stay there too
    name: route.matched[0].name === 'classic_pages' ? 'wiki_article' : 'dashboard_wiki_article',
    params: {
        articleId: article.id,
        articleName: slug(article.nom)
    }
});

export default {
    ROLES_AVAILABLE_READ_ONLY,
    ROLES_AVAILABLE_EDIT,
    ROLES_AVAILABLE,
    momentDatesArticle,
    momentDatesBrouillon,
    isFolderEmpty,
    getRoleSelectOption,
    canEditArticle,
    canDeleteArticle,
    linkToArticlePage,
    linkToWikiHome
};